import React from "react";
import styled from "styled-components";

export default ({ className }) => (
  <Svg
    viewBox="0 0 1920 87"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={className}
  >
    <TransparentWave d="M1920.11,27.7V87H.11V17.65C203.24,46.37,372.22,55.93,522.77,55.93c278.26,0,493.76-32.6,746.78-37,53.67-.93,107.29-1.33,159.82-1.33C1624.21,17.61,1804.39,23.13,1920.11,27.7Z" />
    <MainWave d="M232.51,76.13C307.43,83.41,376,86.59,440.49,87H.11V43.23q29.54,5.39,57.85,10,15.6,2.55,30.83,4.86l9.11,1.38q7.29,1.08,14.51,2.12l2.24.33,5.28.75,10.49,1.46,5.22.71,2.06.28,10.91,1.44q5.43.7,10.81,1.39,29.67,3.75,58,6.67l1.48.15Q225.77,75.47,232.51,76.13Zm963-32.92c-280.63,0-474,42.14-725.38,43.79H1920.11V82.49S1624.89,43.21,1195.54,43.21Z" />
  </Svg>
);

const Svg = styled.svg`
  display: block;
`;

const TransparentWave = styled.path`
  fill: ${({ theme }) => theme.colors.baseSub};
  fill-opacity: 0.25;
`;

const MainWave = styled.path`
  fill: ${({ theme }) => theme.colors.base};
`;
