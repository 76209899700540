import React from "react";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Container from "../components/Container";
import SEO from "../components/SEO";
import Row from "../components/Row";
import Column from "../components/Column";
import Image from "../components/Image";
import IndexHeader from "../components/IndexHeader";
import LinkButton from "../components/LinkButton";
import CareersBanner from "../components/CareersBanner";
import Card from "../components/Card";
import { max_md, min_lg } from "../utils/mediaQuery";

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO />
      <IndexHeader />
      <Container large>
        <Section>
          <SectionHeading>Services</SectionHeading>
          <SectionSubHeading>サービス</SectionSubHeading>
          <ServicesRowBase>
            <Row justifyContent="center">
              <Column col={8}>
                <ServiceBase>
                  <ServiceSocialDog
                    href="https://social-dog.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ServiceSocialDogGradient
                      filename="socialdog_tiles_gradient.png"
                      alt=""
                      width="1287"
                      height="365"
                    />
                    <ServiceSocialDogScreens
                      filename="socialdog_tiles.png"
                      alt=""
                      width="787"
                      height="568"
                    />
                    <ServiceSocialDogContent>
                      <ServiceSocialDogLogo
                        filename="socialdog_logo.png"
                        alt="SocialDog"
                        width="700"
                        height="163"
                      />
                      <ServiceLead>
                        SNS運用担当者のための
                        <br />
                        マーケティングツール
                      </ServiceLead>
                      <ServiceSocialDogButton>
                        サービスをみる
                      </ServiceSocialDogButton>
                    </ServiceSocialDogContent>
                  </ServiceSocialDog>
                </ServiceBase>
              </Column>
            </Row>
          </ServicesRowBase>
        </Section>
        <Section>
          <SectionHeading>News</SectionHeading>
          <SectionSubHeading>ニュース</SectionSubHeading>
          <NewsRowBase>
            <Row>
              {data.allMarkdownRemark.edges.map(
                edge =>
                  !edge.node.frontmatter.isDummyFile && (
                    <Column col={4} key={edge.node.id}>
                      <NewsCardBase>
                        <Card
                          as={Link}
                          to={`/news/${edge.node.frontmatter.slug}`}
                        >
                          <NewsDate>
                            {edge.node.frontmatter.publishedAt}
                          </NewsDate>
                          <NewsTitle>{edge.node.frontmatter.title}</NewsTitle>
                          <NewsDescription>{edge.node.excerpt}</NewsDescription>
                        </Card>
                      </NewsCardBase>
                    </Column>
                  )
              )}
            </Row>
          </NewsRowBase>
          <Center>
            <LinkButton to="/news/">もっとみる</LinkButton>
          </Center>
        </Section>
      </Container>
      <CareersBanner />
    </>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 6
      sort: { order: DESC, fields: [frontmatter___publishedAt] }
      filter: { fields: { collectionType: { eq: "news" } } }
    ) {
      edges {
        node {
          id
          excerpt(truncate: true)
          frontmatter {
            isDummyFile
            publishedAt(formatString: "YYYY/M/D")
            slug
            title
          }
        }
      }
    }
  }
`;

const Section = styled.section`
  margin: 0 0 150px;
  &:last-child {
    margin: 0;
  }

  ${max_md(css`
    margin: 0 0 75px;
  `)}
`;

const SectionHeading = styled.h2`
  color: ${({ theme }) => theme.colors.main};
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 10px;

  ${max_md(css`
    font-size: 24px;
    text-align: center;
  `)}
`;

const SectionSubHeading = styled.div`
  color: ${({ theme }) => theme.colors.muted};
  font-size: 14px;
  line-height: 1.2;
  margin: 0;

  ${max_md(css`
    text-align: center;
  `)}
`;

const ServicesRowBase = styled.div`
  padding: 16px 0 0;
`;

const ServiceBase = styled.div`
  height: 100%;
  padding: 16px 0;

  ${min_lg(css`
    padding-bottom: 0;
  `)}
`;

const ServiceStyle = css`
  border-radius: 24px;
  padding: 40px 20px;
  color: ${({ theme }) => theme.colors.normalOnMain};
  text-decoration: none;
  height: 100%;
  overflow: hidden;
  transition: 0.25s ease box-shadow, 0.25s ease transform;

  ${min_lg(css`
    padding: 40px;
  `)}
`;

const ServiceButtonStyle = css`
  border-radius: 24px;
  height: 48px;
  background: ${({ theme }) => theme.colors.main};
  padding: 0 32px;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
`;

const ServiceSocialDog = styled.a`
  ${ServiceStyle}
  background: ${({ theme }) => theme.colors.mainLight};
  display: flex;
  align-items: center;
  box-shadow: 0 4px 24px 0 rgba(137, 145, 154, 0.32);
  flex-direction: column;
  position: relative;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px 0 rgba(137, 145, 154, 0.64);
  }

  ${min_lg(css`
    flex-direction: row;
  `)}
`;

const ServiceSocialDogGradient = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 0;

  ${min_lg(css`
    left: -200px;
  `)}
`;

const ServiceSocialDogScreens = styled(Image)`
  display: block;
  width: 100%;
  max-width: 460px;
  height: auto;
  margin: 0 0 24px;
  z-index: 1;

  ${min_lg(css`
    width: auto;
    max-width: 60%;
    flex: 0 0 60%;
    margin: 0;
  `)}
`;

const ServiceSocialDogContent = styled.div`
  padding: 0;
  text-align: center;
  z-index: 1;

  ${min_lg(css`
    padding: 0 0 0 8px;
    max-width: 40%;
    flex: 0 0 40%;
  `)}
`;

const ServiceSocialDogButton = styled.div`
  ${ServiceButtonStyle}
  color: ${({ theme }) => theme.colors.normalOnMain};
`;

const ServiceLogoStyle = css`
  display: block;
  width: 100%;
  height: auto;
  max-width: 200px;
  margin: 0 auto 16px;
`;

const ServiceSocialDogLogo = styled(Image)`
  ${ServiceLogoStyle}
`;

const ServiceLead = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.normal};
  margin: 0 0 20px;
`;

const NewsRowBase = styled.div`
  padding: 64px 0 8px;

  ${max_md(css`
    padding: 32px 0 8px;
  `)}
`;

const NewsCardBase = styled.div`
  padding: 0 0 32px;
  height: 100%;
  /* display: flex により、内部の Card が高さ 100% となる */
  display: flex;
`;

const NewsDate = styled.div`
  color: ${({ theme }) => theme.colors.main};
  font-weight: bold;
  margin: 0 0 8px;
`;

const NewsTitle = styled.div`
  color: ${({ theme }) => theme.colors.normal};
  font-weight: bold;
  margin: 0 0 8px;
`;

const NewsDescription = styled.div`
  color: ${({ theme }) => theme.colors.normal};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Center = styled.div`
  text-align: center;
`;

export default IndexPage;
