import React from "react";
import styled, { css } from "styled-components";
import Container from "./Container";
import LinkButton from "./LinkButton";
import IndexHeaderMap from "./IndexHeaderMap";
import IndexHeaderWave from "./IndexHeaderWave";
import { headerHeight } from "./Header";
import { max_md } from "../utils/mediaQuery";
import useGatsbyImageSrc from "../hooks/useGatsbyImageSrc";

export default () => {
  const [mapImageSrc] = useGatsbyImageSrc("map.png");

  return (
    <Header mapImageSrc={mapImageSrc}>
      <StyledIndexHeaderMap />
      <StyledIndexHeaderWave />
      <Container large>
        <Content>
          <Heading>
            {"あらゆる人がSNSを活用できる世界を創る"
              .split("")
              .map((word, index) => (
                <React.Fragment key={`${word}${index}`}>
                  <HeadingWord
                    style={{ "--letter-index": index }}
                    aria-hidden="true"
                    translate="no"
                  >
                    {word}
                  </HeadingWord>
                </React.Fragment>
              ))}
            <HeadingAlternative>
              {/* スクリーンリーダー読み上げ用テキスト。画面上には表示されない。 */}
              あらゆる人がSNSを活用できる世界を創る
            </HeadingAlternative>
          </Heading>
          <Description>
            SocialDogは、あらゆる人や組織が、よりよくSNSを活用できる世界を創っていきます。
          </Description>
          <LinkButton to="/about/" inverse>
            私達についてもっとみる
          </LinkButton>
        </Content>
      </Container>
    </Header>
  );
};

const Header = styled.div`
  overflow: hidden;
  height: calc(100vh - 200px);
  min-height: 800px;
  margin: 0 0 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* コンテンツがヘッダーと被らないように上paddingをとる */
  padding: ${headerHeight} 0 100px;

  ${max_md(css`
    margin: 0 0 50px;
    padding: 0 0 25px;
  `)}
`;

const StyledIndexHeaderMap = styled(IndexHeaderMap)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledIndexHeaderWave = styled(IndexHeaderWave)`
  position: absolute;
  left: 0;
  right: 0;
  /* ウェーブ画像と青背景部分の間にわずかなギャップが表示されないよう、
  0ではなく1pxとした。 */
  bottom: -1px;
  height: 100px;
  width: 100%;

  ${max_md(css`
    height: 25px;
  `)}
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 40px;
  line-height: 1.4;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.normalOnMain};
  margin: 0 0 16px;
`;

const HeadingWord = styled.span`
  display: inline-block;

  @media (prefers-reduced-motion: no-preference) {
    animation: 0.75s ease calc(var(--letter-index) * 0.025s) headingWord both;
  }

  @keyframes headingWord {
    from {
      opacity: 0;
      transform: translateY(10px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const HeadingAlternative = styled.span`
  /* スクリーンリーダーによる読み上げのしやすさを改善。 */
  /* https://www.tak-dcxi.com/article/text-animation-considerations-and-implementation-examples */
  position: fixed !important;
  inset: 0 !important;
  display: block !important;
  inline-size: 4px !important;
  block-size: 4px !important;
  contain: strict !important;
  pointer-events: none !important;
  opacity: 0 !important;
`;

const Description = styled.p`
  margin: 0 0 32px;
  color: ${({ theme }) => theme.colors.mutedOnMain};
  font-size: 16px;
  font-weight: bold;
  line-height: 2;

  @media (prefers-reduced-motion: no-preference) {
    animation: 0.75s ease headingWord 0.5s both;
  }

  ${max_md(css`
    font-size: 14px;
    margin: 0 0 24px;
  `)}
`;
